<template>
  <div>
    <div class="image-slider-container">
      <!-- <CarouselParallax /> -->
      <div
        id="carouselExampleIndicators"
        class="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <!-- <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
        </div> -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="../../assets/images/street/1.jpeg"
              class="d-block w-100"
              alt=""
            />
          </div>

          <div class="carousel-item">
            <img
              src="../../assets/images/street/2.jpeg"
              class="d-block w-100"
              alt=""
            />
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/images/street/3.jpeg"
              class="d-block w-100"
              alt=""
            />
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/images/street/4.jpeg"
              class="d-block w-100"
              alt=""
            />
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/images/street/5.jpeg"
              class="d-block w-100"
              alt=""
            />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <div class="product-index text-center my-5 py-5">
      <div class="container">
        <h1 class="mb-5 display-1 fw-bold">الأصناف الرئيسية</h1>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4 my-1">
            <a href="/products/street"
              ><img
                src="@/assets/images/button/Asset4.png"
                class="img-fluid"
                alt=""
            /></a>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 my-1">
            <a href="/products/interface">
              <img
                src="@/assets/images/button/Asset5.png"
                class="img-fluid"
                alt=""
            /></a>
            <!-- <img
              src="@/assets/images/button/Asset5.png"
              class="img-fluid"
              alt=""
              @click="$router.push(`/products/interface`)"
            /> -->
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 my-1">
            <a href="/products/highbay">
              <img
                src="@/assets/images/button/Asset6.png"
                class="img-fluid"
                alt=""
            /></a>
            <!-- <img
              src="@/assets/images/button/Asset6.png"
              class="img-fluid"
              alt=""
              @click="$router.push(`/products/highbay`)"
            /> -->
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 my-1 m-auto">
            <a href="/products/twilight">
              <img
                src="@/assets/images/button/Asset7.png"
                class="img-fluid"
                alt=""
            /></a>
            <!-- <img
              src="@/assets/images/button/Asset7.png"
              class="img-fluid"
              alt=""
              @click="$router.push(`/products/twilight`)"
            /> -->
          </div>
        </div>
      </div>
      <div
        class="
          row
          justify-content-center
          align-items-center
          mt-5
          mx-0
          px-3
          cataloge-section
        "
      >
        <div class="cataloge col-sm-10 col-md-6 m-5 p-3 p-sm-4 shadow-lg">
          <p class="h1 my-5">
            لتحميل الدليل الكامل لمنتجات شركة الصباغ للصناعات الكهربائية
          </p>
          <a
            :href="
              `https://drive.google.com/uc?export=download&id=1sl48z6pDJO_iBbDH8l6oGRnZoh5lNuu-`
            "
            download
            class="btn btn-dark btn-lg"
          >
            <i class="fa fa-download" aria-hidden="true"></i> تحميل
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-slider-container img {
  height: 90vh;
  object-fit: cover;
}

.col-sm-12 img {
  cursor: pointer;
}

.cataloge-section {
  background-image: url("../../assets/images/cataloge.jpg");
  background-position: center;
  background-size: cover;
  min-height: 70vh;
}

.cataloge {
  background-color: rgba(242, 242, 242, 0.8);
  backdrop-filter: blur(2px);
  border-radius: 1em;
}

.cataloge .btn-lg {
  padding: 0.6em 1.6em !important;
}
</style>
